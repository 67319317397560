import api from '@apiInstance';

const model = '/claims';
export default {
  add(data) {
    return api.post(model, data);
  },
  get(id) {
    return api.fetch(`${model}/${id}`);
  },
  list(params) {
    return api.fetch(`${model}`, { params });
  },
  update(data) {
    return api.patch(model, data);
  },
  delete(id) {
    return api.remove(model, id);
  },

  verify(body) {
    return api.request({
      url: `${model}/verify`,
      method: `POST`,
      body,
      type: `ot_claims`,
    });
  },
};
