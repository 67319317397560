<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <small v-if="+$route.query.id">
          last edited: {{ serviceRequestData.prev_updated_at }}
        </small>
        <h2>Service Request Information</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <label for="">
          RFQ No.: <strong>{{ serviceRequestData.orderno }}</strong>
        </label>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <label for="">
          Company: <strong>{{ serviceRequestData.company }}</strong>
        </label>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <label for="">
          Email: <strong>{{ serviceRequestData.email }}</strong>
        </label>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <label for="">
          Sales Person:
          <strong>{{ getEmployee(serviceRequestData.employee_id) }}</strong>
        </label>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <label for="">
          Request Date: <strong>{{ serviceRequestData.prev_date }}</strong>
        </label>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <label for="">
          Status: <strong>{{ getStatus(serviceRequestData.status) }}</strong>
        </label>
      </b-col>
    </b-row>

    <!-- Billing Address -->
    <br />
    <b-row>
      <b-col>
        <label for="">
          Billing Address:
          <div class="">
            <strong>{{ serviceRequestData.billing_name }}</strong>
          </div>
          <div class="">
            <strong>{{ serviceRequestData.billing_tel }}</strong>
          </div>
          <div class="">
            <strong>{{ getAddress(serviceRequestData) }}</strong>
          </div>
          <div class="">
            <strong>{{ serviceRequestData.billing_country }}</strong>
            &nbsp;
            <strong>{{ serviceRequestData.billing_postal }}</strong>
          </div>
        </label>
      </b-col>
    </b-row>

    <!-- Delivery Address -->
    <br />
    <b-row>
      <b-col>
        <label for="">
          Delivery Address:
          <div class="">
            <strong>{{ serviceRequestData.delivery_name }}</strong>
          </div>
          <div class="">
            <strong>{{ serviceRequestData.delivery_tel }}</strong>
          </div>
          <div class="">
            <strong>{{ getAddress(serviceRequestData, 'delivery') }}</strong>
          </div>
          <div class="">
            <strong>{{ serviceRequestData.delivery_country }}</strong>
            &nbsp;
            <strong>{{ serviceRequestData.delivery_postal }}</strong>
          </div>
        </label>
      </b-col>
    </b-row>

    <!-- GST -->
    <br />
    <b-row>
      <b-col>
        <label for="">
          GST:
          <strong>{{ serviceRequestData.gst }}</strong>
        </label>
      </b-col>
      <b-col>
        <label for="">
          GST Percent:
          <strong>{{ serviceRequestData.gst_percent }}%</strong>
        </label>
      </b-col>
    </b-row>

    <!-- Rfq Details -->
    <br />
    <b-row>
      <b-col>
        <label for="">
          RFQ Details:
          <b-table
            striped
            hover
            small
            responsive
            :items="rfqDetails"
            :fields="rfqDetailFields"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width: field.key == 'show_details' ? '170px' : '500px',
                }"
              />
            </template>

            <template #cell(name)="data">
              <div class="ml-5">
                {{ data.prod_name }}
              </div>
            </template>

            <template #cell(show_details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <span class="vs-label">
                  {{ row.detailsShowing ? 'Hide' : 'Show' }}
                </span>
              </b-form-checkbox>
            </template>

            <template #row-details="row">
              <b-card>
                <b-card-text>
                  <b-row class="mb-2">
                    <b-col md="4" class="mb-1">
                      <strong>Price : </strong>
                      <div class="">
                        {{ getMoneyFormat(row.item.price) }}
                      </div>
                    </b-col>
                    <b-col md="4" class="mb-1">
                      <strong>Quantity : </strong>
                      <div class="">
                        {{ getMoneyFormat(row.item.qty) }}
                      </div>
                    </b-col>
                    <b-col md="4" class="mb-1">
                      <strong>Total : </strong>
                      <div class="">
                        {{ getMoneyFormat(row.item.total) }}
                      </div>
                    </b-col>
                    <b-col md="4" class="mb-1">
                      <strong>Discount Percent : </strong>
                      <div class="">
                        {{ getMoneyFormat(row.item.discount_percent) }}
                      </div>
                    </b-col>
                    <b-col md="4" class="mb-1">
                      <strong>Discount Amount : </strong>
                      <div class="">
                        {{ getMoneyFormat(row.item.discount_amount) }}
                      </div>
                    </b-col>
                    <b-col md="4" class="mb-1">
                      <strong>Gross Amount : </strong>
                      <div class="">
                        {{ getMoneyFormat(row.item.gross_amount) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card>
            </template>
          </b-table>
        </label>
      </b-col>
    </b-row>

    <!-- Notes -->
    <br />
    <b-row>
      <b-col>
        <label for="">
          Notes:
          <div class="">
            <strong v-html="serviceRequestData.notes"></strong>
          </div>
        </label>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api';
import employeeApi from '@api/employees';
import rfqDetailApi from '@api/rfq_details';
import { moneyFormat as mf } from '@/global-functions';
import { queryParameters } from '@/schema';

export default {
  name: 'JobOrderQuickView',
  props: {
    jobOrderData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    function getOperationStatus(data) {
      let status = 'New';
      switch (data) {
        case 'A':
          status = 'Approved';
          break;

        case 'S':
          status = 'Submitted';
          break;

        case 'R':
          status = 'Rejected';
          break;
      }

      return status;
    }

    function getStatus(data) {
      let status = 'Open';
      switch (data) {
        case 'N':
          status = 'New';
          break;
        case 'R':
          status = 'Reopen';
          break;
        case 'C':
          status = 'Confirmed';
          break;
        case 'V':
          status = 'Void';
          break;

        default:
          break;
      }

      return status;
    }

    const employeeList = ref(null);
    loadEmployees();
    function loadEmployees() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      employeeApi
        .list(params)
        .then(({ data }) => {
          employeeList.value = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    }

    function getEmployee(id) {
      if (employeeList.value) {
        const employee = employeeList.value.find((x) => {
          return +x.id == id;
        });

        if (employee) {
          return employee.fullname;
        }
      }
    }

    const rfqDetailFields = [
      'show_details',
      { key: 'prod_name', label: 'Product' },
    ];
    const rfqDetails = ref(null);
    loadRfqDetails();
    function loadRfqDetails() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.filterExt = `header_id=${props.serviceRequestData.id}`;

      rfqDetailApi
        .list(params)
        .then(({ data }) => {
          rfqDetails.value = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    }

    function getAddress(data, field = 'billing') {
      if (field != 'billing') {
        let addr = data.delivery_addr1;
        if (data.delivery_addr2) {
          addr += ` ${data.delivery_addr2}`;
        }

        return addr;
      }

      let addr = data.billing_addr1;
      if (data.billing_addr2) {
        addr += ` ${data.billing_addr2}`;
      }
      return addr;
    }

    function getMoneyFormat(value) {
      if (!value) {
        return 0;
      }

      return mf(value);
    }

    return {
      rfqDetails,
      rfqDetailFields,
      getOperationStatus,
      getStatus,
      getEmployee,
      getAddress,
      getMoneyFormat,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
